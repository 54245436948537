
import { BlogModel } from 'tradingmate-modules/src/models/api/blogs'
import { Services } from 'tradingmate-modules/src/services'
import { Vue, Component, Watch } from 'vue-property-decorator'
import PostNav from '@/components/layout/PostNav.vue'
import FacebookShare from '@/components/actions/FacebookShare.vue'
import Utils from 'tradingmate-modules/src/Utils'
import FormattedText from '@/components/typography/FormattedText.vue'
import Gallery from '@/components/layout/Gallery.vue'
import BlogAnalyticEventCreateDto from 'tradingmate-modules/src/models/api/analytic-event/BlogAnalyticalEventCreateDto'
import BlogAnalyticEventType from 'tradingmate-modules/src/models/api/analytic-event/BlogAnalyticEventType'

@Component({
  components: {
    FormattedText,
    PostNav,
    FacebookShare,
    Gallery
  }
})
export default class ToolboxTalkDetails extends Vue {
  private blog: BlogModel | null = null
  private isoToDisplayDate = Utils.isoToDisplayDate

  get galleryImages (): string[] | null {
    var images = this.blog?.Images?.map((img) => { return img.Normal }) ?? null
    return images
  }

  async mounted (): Promise<void> {
    await this.getToolboxTalkPost()
    this.pagedViewed()
  }

  @Watch('$route')
  async getToolboxTalkPost (): Promise<void> {
    var slug = this.$route.params.slug
    await Services.API.Blogs.GetBlogBySlug(slug)
      .then(returnBlog => { this.blog = returnBlog })
  }

  handlePrevPost (): void {
    var slug = this.$route.params.slug
    Services.API.Blogs.PreviousBlog(slug).then(returnBlog => {
      this.$router.push({
        name: 'Toolbox Talk Details',
        params: {
          slug: returnBlog.Slug
        }
      })
    })
  }

  handleNextPost (): void {
    var slug = this.$route.params.slug
    Services.API.Blogs.NextBlog(slug).then(returnBlog => {
      this.blog = returnBlog
      this.$router.push({
        name: 'Toolbox Talk Details',
        params: {
          slug: returnBlog.Slug
        }
      })
    })
  }

  private viewedDto: BlogAnalyticEventCreateDto = { Type: BlogAnalyticEventType.BlogView }
  pagedViewed (): void {
    if (this.blog === null) return
    Services.API.AnalyticEvent.CreateBlogAnalyticEvent(this.blog.BlogId, this.viewedDto)
  }
}
